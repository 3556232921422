var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-flix-p',[_c('b-flix-alert',{attrs:{"variant":"info"}},[_vm._v(_vm._s(_vm.$t('message.eventsOverviewInfo')))])],1),(_vm.loading)?_c('b-flix-p',[_c('a',{staticClass:"flix-btn flix-btn-default",class:!_vm.all ? 'flix-btn-success' : '',attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => {
          _vm.all = !_vm.all
          _vm.filter = !_vm.filter
          _vm.getOnlyAppointmentsWithCustomers()
          _vm.setFilter()
        }).apply(null, arguments)}}},[_c('b-flix-icon',{attrs:{"icon":"user"}}),_vm._v(" Termine ohne Teilnehmer ausblenden")],1)]):_vm._e(),(!_vm.loading)?_c('b-flix-loader'):(!_vm.bookings)?_c('div',{staticClass:"flix-alert flix-alert-danger"},[_vm._v(" "+_vm._s(_vm.$tc('message.noEntry', 2))+" ")]):(_vm.loading && _vm.bookings)?_c('div',[_c('div',[_c('a',{staticClass:"flix-btn flix-btn-default",class:_vm.filter ? 'flix-btn-success' : '',attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => {
            _vm.setFilter()
          }).apply(null, arguments)}}},[_c('b-flix-icon',{attrs:{"icon":"filter"}}),_vm._v(" nur "+_vm._s(_vm.$tc('message.nextAppointments', 2)))],1),_c('a',{staticClass:"flix-btn flix-btn-default",class:!_vm.filter ? 'flix-btn-success' : '',attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return (() => {
            _vm.setFilter()
          }).apply(null, arguments)}}},[_c('b-flix-icon',{attrs:{"icon":"filter"}}),_vm._v(" auch vergangene Termine")],1)]),_vm._l((_vm.bookings),function(calendar){return _c('div',{key:calendar.ID,staticStyle:{"margin":"50px 0"}},[_c('b-flix-p',[_c('b-flix-h2',[_c('b-flix-icon',{attrs:{"icon":"calendar"}}),_vm._v(" "+_vm._s(calendar.title))],1)],1),_vm._l((calendar.bookings),function(booking,index){return _c('b-flix-p',{key:index},[_c('bookingflix-calendar-items',{key:booking.begin + booking.end,attrs:{"settings":JSON.stringify({
              lang: _vm.$i18n.locale,
              view: 'timer',
              date: [booking.end, booking.end]
            })}}),_c('b-flix-h5',{staticStyle:{"display":"block","margin-bottom":"10px"}},[_c('bookingflix-calendar-items',{key:booking.begin + booking.end,attrs:{"settings":JSON.stringify({
                lang: _vm.$i18n.locale,
                view: 'date-string',
                date: [booking.begin, booking.end]
              })}})],1),_c('div',{staticClass:"chart-container"},[_c('div',{staticClass:"chart-line",style:({ width: booking.percentage + '%' })})]),_c('b-flix-p',[_c('b-flix-icon',{attrs:{"icon":"user"}}),_vm._v(" "+_vm._s(booking.bookings)+"/"+_vm._s(booking.total)+" "),(booking.bookings)?_c('router-link',{staticClass:"flix-btn flix-btn-success flix-btn-xs",attrs:{"to":{
              name: 'specificBookingCalendar',
              params: { date: booking.begin.split(' ')[0], id: calendar.ID }
            }}},[_vm._v(_vm._s(_vm.$t('message.show', { name: _vm.$tc('message.appointments', booking.bookings) })))]):_vm._e(),_c('router-link',{staticClass:"flix-btn flix-btn-default flix-btn-xs",attrs:{"to":{
              name: 'dashboardAssistentEdit',
              params: { id: calendar.ID }
            }}},[_vm._v(_vm._s(_vm.$t('message.edit', { name: _vm.$tc('message.appointments', booking.bookings) }))+" ("+_vm._s(_vm.$t('message.quickstart'))+")")])],1),_c('hr',{staticClass:"flix-html-hr flix-hr"})],1)})],2)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }