<template>
  <div>
    <b-flix-p>
      <b-flix-alert variant="info">{{
        $t('message.eventsOverviewInfo')
      }}</b-flix-alert>
    </b-flix-p>
    <b-flix-p v-if="loading"
      ><a
        href="#"
        class="flix-btn flix-btn-default"
        :class="!all ? 'flix-btn-success' : ''"
        @click.prevent="
          () => {
            all = !all
            filter = !filter
            getOnlyAppointmentsWithCustomers()
            setFilter()
          }
        "
        ><b-flix-icon icon="user"></b-flix-icon> Termine ohne Teilnehmer
        ausblenden</a
      ></b-flix-p
    >
    <b-flix-loader v-if="!loading"></b-flix-loader>
    <div v-else-if="!bookings" class="flix-alert flix-alert-danger">
      {{ $tc('message.noEntry', 2) }}
    </div>
    <div v-else-if="loading && bookings">
      <div>
        <a
          href="#"
          class="flix-btn flix-btn-default"
          :class="filter ? 'flix-btn-success' : ''"
          @click.prevent="
            () => {
              setFilter()
            }
          "
          ><b-flix-icon icon="filter"></b-flix-icon> nur
          {{ $tc('message.nextAppointments', 2) }}</a
        >
        <a
          href="#"
          class="flix-btn flix-btn-default"
          :class="!filter ? 'flix-btn-success' : ''"
          @click.prevent="
            () => {
              setFilter()
            }
          "
          ><b-flix-icon icon="filter"></b-flix-icon> auch vergangene Termine</a
        >
      </div>
      <div
        style="margin: 50px 0"
        v-for="calendar in bookings"
        :key="calendar.ID"
      >
        <b-flix-p>
          <b-flix-h2
            ><b-flix-icon icon="calendar"></b-flix-icon>
            {{ calendar.title }}</b-flix-h2
          >
        </b-flix-p>
        <b-flix-p v-for="(booking, index) in calendar.bookings" :key="index">
          <bookingflix-calendar-items
            :key="booking.begin + booking.end"
            :settings="
              JSON.stringify({
                lang: $i18n.locale,
                view: 'timer',
                date: [booking.end, booking.end]
              })
            "
          ></bookingflix-calendar-items>
          <b-flix-h5 style="display: block; margin-bottom: 10px">
            <bookingflix-calendar-items
              :key="booking.begin + booking.end"
              :settings="
                JSON.stringify({
                  lang: $i18n.locale,
                  view: 'date-string',
                  date: [booking.begin, booking.end]
                })
              "
            ></bookingflix-calendar-items>
          </b-flix-h5>

          <div class="chart-container">
            <div
              class="chart-line"
              :style="{ width: booking.percentage + '%' }"
            ></div>
          </div>
          <b-flix-p>
            <b-flix-icon icon="user"></b-flix-icon> {{ booking.bookings }}/{{
              booking.total
            }}
            <router-link
              v-if="booking.bookings"
              :to="{
                name: 'specificBookingCalendar',
                params: { date: booking.begin.split(' ')[0], id: calendar.ID }
              }"
              class="flix-btn flix-btn-success flix-btn-xs"
              >{{
                $t('message.show', {
                  name: $tc('message.appointments', booking.bookings)
                })
              }}</router-link
            >
            <router-link
              :to="{
                name: 'dashboardAssistentEdit',
                params: { id: calendar.ID }
              }"
              class="flix-btn flix-btn-default flix-btn-xs"
              >{{
                $t('message.edit', {
                  name: $tc('message.appointments', booking.bookings)
                })
              }}
              ({{ $t('message.quickstart') }})</router-link
            >
          </b-flix-p>
          <hr class="flix-html-hr flix-hr" />
        </b-flix-p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bookedEvents',
  components: {},
  props: {},
  data() {
    return {
      totalBookings: false,
      bookings: false,
      loading: false,
      filter: false,
      all: true
    }
  },
  computed: {},
  mounted() {
    this.getBookings()
  },
  methods: {
    getOnlyAppointmentsWithCustomers() {
      if (this.all) {
        this.bookings = JSON.parse(JSON.stringify(this.totalBookings))
        return false
      }

      const bookings = []
      JSON.parse(JSON.stringify(this.totalBookings)).forEach((cal) => {
        cal.bookings = cal.bookings.filter((b) => {
          return b.bookings * 1 > 0 ? b : false
        })
        if (cal.bookings.length) {
          bookings.push(cal)
        }
      })

      this.bookings = bookings
    },
    setFilter() {
      this.loading = false
      this.filter = !this.filter

      if (!this.filter) {
        this.getOnlyAppointmentsWithCustomers()
        this.loading = true
        return false
      }

      const now = new Date()
      const bookings = JSON.parse(JSON.stringify(this.bookings)).filter(
        (b, index) => {
          b.bookings = b.bookings.filter((c) => {
            if (this.$createDate(c.end).getTime() > now.getTime()) {
              return c
            }
            return false
          })
          if (b.bookings.length) {
            return b
          }
          return false
        }
      )

      this.bookings = bookings

      this.loading = true
    },
    getBookings() {
      this.$flix_post({
        url: 'booking/event_bookings',
        data: {
          user: this.$getUserVariables().user.md5_id,
          all: this.all
        },
        callback: (data) => {
          this.loading = true
          if (data.data[0]) {
            this.totalBookings = data.data[1]
            this.bookings = data.data[1]
          }
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.chart-container
  position: relative
  max-width: 300px
  width: 100%
  height: 10px
  background: #eee
  display: block
  .chart-line
    position: absolute
    top: 0
    left: 0
    height: 100%
    background: #42bd84
    display: block
</style>
